import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegistrationConfig } from '../../interfaces/registration';

export const registrationConfigSlice = createSlice({
  name: 'registrationConfig',
  initialState: {
    ywrcpdEvidenceCourse: [],
    ywrcpdEvidenceCPDType: [],
    ywrcpdEvidenceStatus: [],
    ywrQualificationCourse: [],
    ywrQualificationInstitution: [],
    ywrQualificationTitle: [],
    ywrRefereeApplicantsRelationship: [],
    ywrPositions: []
  } as IRegistrationConfig,
  reducers: {
    populate: (state, action: PayloadAction<IRegistrationConfig>) => {
      return action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { populate } = registrationConfigSlice.actions;

export default registrationConfigSlice.reducer;
