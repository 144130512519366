import {
  Box,
  Chip,
  DialogContent,
  DialogContentText,
  styled,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { Fragment, LegacyRef, ReactNode, useRef, useState } from 'react';
import useSvgElementHover from '../utils/useSvgElementHover';
import useSvgElementOnClick from '../utils/useSvgElementOnClick';
import { useNavigate } from 'react-router-dom';
import useSvgElementOnEnter from '../utils/useSvgElementOnEnter';
import { useTracking } from '../utils/useTracking';
import { orderBy } from 'lodash';
import Dialog from './dialog';
import Markdown from 'mui-markdown';
import { IHomePageTile } from '../interfaces/homePageTile';
import ResourceIcon from '../assets/YWO_Icon_Resources.png';
import DiscussionIcon from '../assets/YWO_Icon_Discussion-Forums.png';
import CurriculumIcon from '../assets/YWO_Icon_Curriculum.png';
import SafeguardingIcon from '../assets/YWO_Icon_Safeguarding-Hub.png';
import CensusIcon from '../assets/YWO_Icon_Census.png';
import EventsIcon from '../assets/YWO_Icon_Events.png';
import TrainingIcon from '../assets/YWO_Icon_Training.png';
import JobsIcon from '../assets/YWO_Icon_Jobs.png';
import RegisterIcon from '../assets/YWO_Icon_Register.png';
import DataHubIcon from '../assets/YWO_Icon_DataHub.png';
import FundingIcon from '../assets/YWO_Icon_Funding.png';

const HomeTiles = () => {
  const homePageTiles = [
    {
      title: 'Resources',
      linkUrl: '/resources',
      colour: '#92d0d6',
      icon: ResourceIcon,
      order: 1,
      active: true
    },
    {
      title: 'Discussion Forums',
      linkUrl: 'https://forum.youthworkone.org.uk/',
      colour: '#4d9acc',
      icon: DiscussionIcon,
      order: 2,
      active: true
    },
    {
      title: 'Curriculum',
      linkUrl: 'https://www.nya.org.uk/quality/curriculum',
      colour: '#79b2d8',
      icon: CurriculumIcon,
      redirectMessage:
        'You are now being redirected to the NYA website where the curriculum is located. If you are not automatically redirected, please click the following link: [https://www.nya.org.uk/quality/curriculum/](https://www.nya.org.uk/quality/curriculum)',
      order: 4,
      active: true
    },
    {
      title: 'Safeguarding & Risk Hub',
      linkUrl:
        'https://www.nya.org.uk/skills/safeguarding-and-risk-management-hub',
      colour: '#a6cce5',
      icon: SafeguardingIcon,
      redirectMessage:
        'You are now being redirected to the NYA website where the Safeguarding and Risk Management hub is located. If you are not automatically redirected, please click the following link: [https://www.nya.org.uk/skills/safeguarding-and-risk-management-hub/](https://www.nya.org.uk/skills/safeguarding-and-risk-management-hub/)',
      order: 3,
      active: true
    },
    {
      title: 'Census+',
      linkUrl: '/census',
      colour: '#a6cce5',
      icon: CensusIcon,
      order: 5,
      active: true
    },
    {
      title: 'Events Booking',
      linkUrl: '/events',
      colour: '#a0d6db',
      icon: EventsIcon,
      order: 6,
      active: true
    },
    {
      title: 'Training',
      linkUrl: '/training',
      colour: '#7fc8d0',
      icon: TrainingIcon,
      order: 7,
      active: true
    },
    {
      title: 'Jobs Board',
      linkUrl: '/jobsboard',
      colour: '#6dc0c9',
      icon: JobsIcon,
      order: 8,
      active: true
    },
    {
      title: 'Youth Worker Register',
      linkUrl: '/youthworkerregister',
      colour: '#66a7d3',
      icon: RegisterIcon,
      order: 9,
      active: true
    },
    {
      title: 'Data Hub',
      linkUrl: '/datahub',
      colour: '#a6cce5',
      icon: DataHubIcon,
      order: 10,
      active: true
    },
    {
      title: 'Funding',
      linkUrl: '/funders',
      colour: '#79B2D8',
      icon: FundingIcon,
      order: 11,
      active: true
    }
  ] as IHomePageTile[];

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 4, flex: '1 1 auto' }}>
        {orderBy(homePageTiles, ['order'], ['asc']).map((tile) => {
          return (
            <Grid key={tile.linkUrl} md={4} sm={4} xs={6}>
              <Tile tile={tile} />
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

interface ITileProps {
  tile: IHomePageTile;
}

const Tile = ({ tile }: ITileProps) => {
  const navigate = useNavigate();
  const tileRef = useRef<SVGSVGElement>(null);
  const trackEvent = useTracking();
  const [redirectMessageOpen, setRedirectMessageOpen] = useState(false);

  const goTo = () => {
    if (tile.redirectMessage) {
      setRedirectMessageOpen(true);
      trackEvent({ action: 'open', value: tile.linkUrl });
      setTimeout(() => {
        window.location.href = tile.linkUrl;
      }, 5000);
    } else if (tile.linkUrl.startsWith('/')) navigate(tile.linkUrl);
    else {
      trackEvent({ action: 'open', value: tile.linkUrl });
      window.open(tile.linkUrl);
    }
  };
  useSvgElementHover<SVGSVGElement>(tileRef, 'hover', 'hoverable');
  useSvgElementOnClick<SVGSVGElement>(tileRef, 'hoverable', goTo, 3);
  useSvgElementOnEnter<SVGSVGElement>(tileRef, 'hoverable', goTo);

  const tooltipText =
    tile.title === 'Youth Worker Register'
      ? 'This is a free, voluntary Register for professionally qualified youth workers or those studying towards achieving this.'
      : '';

  return (
    <TileMain>
      {!tile.active && (
        <TileComingSoon label="Coming Soon!" color="primary" size="small" />
      )}
      <Tooltip title={tooltipText} placement="top" arrow>
        <TileContent>
          <TileBackground innerRef={tileRef} tile={tile}>
            <TileIcon icon={tile.icon} />
            <TileText>
              <Typography
                variant="h4"
                fontSize={{ md: '1.5rem', sm: '1.2rem', xs: '1rem' }}
              >
                {tile.title}
              </Typography>
            </TileText>
          </TileBackground>
          {tile.redirectMessage && (
            <Dialog
              open={redirectMessageOpen}
              onClose={() => setRedirectMessageOpen(false)}
            >
              <DialogContent>
                <DialogContentText>
                  <Markdown>{tile.redirectMessage}</Markdown>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          )}
        </TileContent>
      </Tooltip>
    </TileMain>
  );
};

const TileMain = styled('div')({
  display: 'inline-block',
  height: 0,
  paddingBottom: '85%',
  width: '100%',
  position: 'relative',
  margin: '0 auto'
});

const TileContent = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100%',
  height: '100%',
  maxWidth: '300px',
  maxHeight: '300px'
});

const TileText = styled('div')({
  position: 'absolute',
  left: '50%',
  bottom: 0,
  width: '80%',
  height: '50%',
  margin: 'auto',
  transform: 'translateX(-50%)',
  color: '#fff',
  textAlign: 'center',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const TileComingSoon = styled(Chip)({
  position: 'absolute',
  left: '50%',
  bottom: '10%',
  transform: 'translateX(-50%)',
  zIndex: 99
});

interface ITileBackgroundProps {
  innerRef: LegacyRef<SVGSVGElement> | undefined;
  tile: IHomePageTile;
  children: ReactNode;
}

const TileBackground = ({ innerRef, tile, children }: ITileBackgroundProps) => {
  const svg = (
    <svg
      ref={innerRef}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100%"
      height="100%"
      tabIndex={tile.active ? 0 : -1}
    >
      <defs>
        <clipPath id="circular-border">
          <circle cx="50%" cy="50%" r="45%" />
        </clipPath>
        <clipPath id="avoid-antialiasing-bugs">
          <rect width="100%" height="91%" />
        </clipPath>
        <mask id="shoulders">
          <rect width="100%" height="100%" fill="white" />
          <circle cx="50%" cy="160%" r="75%" fill="black" />
        </mask>
      </defs>
      <circle
        cx="50%"
        cy="50%"
        r="45%"
        fill={tile.active ? tile.colour : 'rgba(0, 0, 0, 0.2)'}
        mask="url(#shoulders)"
        clipPath="url(#circular-border)"
        className={tile.active ? 'hoverable' : ''}
      />
      <foreignObject
        x="5%"
        y="5%"
        width="90%"
        height="80%"
        clipPath="url(#circular-border)"
      >
        {children}
      </foreignObject>
    </svg>
  );

  return svg;
};

interface ITileIconProps {
  icon: string;
}

const TileIcon = ({ icon }: ITileIconProps) => {
  return (
    <Box
      sx={{
        maxHeight: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-75%)',
        userSelect: 'none'
      }}
      component="img"
      src={icon}
      alt="logo"
    />
  );
};

export default HomeTiles;
